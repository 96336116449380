import React from 'react'
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Select from 'react-select';

import { createAccess, createBulkAccess } from '../../actions/accesses';

import { PlusIcon, DashIcon } from '@primer/octicons-react';

class BulkForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      domain: "",
      course_id: "",
      type: "full",
      toggled: false,
    };

    this.domainOptions = [
      { value: 'org.reifam.ausbildung', label: 'org.reifam.ausbildung' },
      { value: 'org.reifam.admin', label: 'org.reifam.admin' },
      { value: 'com.familienort.my', label: 'com.familienort.my' },
    ];
  };

  async handleSubmit(event) {
    event.preventDefault();
    const { domain, course_id, type } = this.state;

    let user_ids = [];

    // Get all user ids from domain.
    try {
      // Call the admin api to find the users.
      const response = await window.usersApi.get(
        `/v1/admin/users?domain=${domain}`
      );

      // Store all ids in array.
      user_ids = response.data.map(user => user.uuid);

    } catch(e) {
      // Do not try to send the request if it failed here.
      console.warn(e);
      toast.error(`Could not fetch users for domain: ${domain}`);
      return;
    }

    this.props.dispatch(createBulkAccess({ user_ids, course_id, type }));
  }

  render() {
    return (
      <div className="mb-6">
        <div className="bg-gray-600 text-gray-100 py-3 px-6 font-bold uppercase text-sm leading-normal flex justify-between w-100">
          <h3>Bulk access</h3>
            <div className="outline-none cursor-pointer" onClick={() => { this.setState({ toggled: !this.state.toggled })}}>
              {
                this.state.toggled ?
                  <DashIcon height={24} className="outline-none" /> :
                  <PlusIcon height={24} className="outline-none" />
              }
            </div>
        </div>

        { this.state.toggled &&
          <form
            className="border w-full rounded-md overflow-hidden p-5 grid grid-cols-6 gap-6 shadow mb-6"
            onSubmit={this.handleSubmit.bind(this)}>

            <div className="col-span-2">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Domain
              </label>

              <Select
                className="inline-block w-full"
                options={this.domainOptions}
                value={this.domainOptions.find((d) => d.value === this.state.domain )}
                isClearable={false}
                onChange={(s) => this.setState({ domain: s.value })} />
            </div>

            <div className="col-span-2">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Course
              </label>
              <select
                onChange={(e) => { this.setState({ course_id: e.target.value })}}
                className="mt-1 w-full bg-white rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none">
                <option>
                  Select
                </option>
                {
                  this.props.courses.map((c) => (
                    <option value={c.uuid} key={c.uuid}>
                      {c.title}
                    </option>
                  ))
                }
              </select>
            </div>

            <div className="col-span-2">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Type
              </label>
              <select
                onChange={(e) => { this.setState({ type: e.target.value })}}
                className="mt-1 w-full bg-white rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none">
                <option value="full">
                  Full
                </option>
                <option value="trial">
                  Trial
                </option>
              </select>
            </div>

            <button
              type="submit"
              className="inline-flex col-start-1 col-end-7 md:col-start-1 md:col-end-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none">
              Save
            </button>

          </form>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users.data,
    courses: state.courses.data,
  }
}

export default connect(mapStateToProps)(BulkForm);
